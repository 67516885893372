import React, {useState} from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './sitemap.module.css'

const SM = () => {

	const query = useStaticQuery(graphql`
	query {
      allStrapiLocations{
          edges{
            node{
              name,
              slug
            }
          }
        }
	  }
	`)

	let p = [];
	query.allStrapiLocations.edges.forEach(({node})=>{
		p.push(node)
	})

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.wrapperSitemap}>
						<div>
							<ul>
								<li><Link to="/about-us">About Us</Link></li>
							</ul>

							<ul>
								<li><Link to="/emergency-services">Emergency services</Link></li>
							</ul>

							<ul>
								<li><Link to="/automobile-lockout">Automobile lockout</Link></li>
							</ul>							

							<ul>
								<li><Link to="/brands-we-help">Car Makes</Link></li>
							</ul>

							<ul>
								<li><Link to="/">Contact us</Link></li>
							</ul>
						</div>

						<div>
							<ul>
								<li><Link to="/faq">FAQ</Link></li>
							</ul>

							<ul>
								<li><Link to="/blog">Blog</Link></li>
							</ul>

							<ul>
								<li><Link to="/privacy-policy">Privacy Policies</Link></li>
							</ul>

							<ul>
								<li><Link to="/security">Security</Link></li>
							</ul>
						</div>

						<div>
							<ul>
								<li><Link to="/locations-in-nyc">Location in NYC</Link></li>
								{
									p.map((location, index)=>{
										return (
											<li>
												<Link to={`/location/${location.slug}`}>{location.name}</Link>
											</li>
											)
										})
									}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SM