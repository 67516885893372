import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import SM from '../components/sitemap/index'

const Sitemap = () => (
  <Layout>
    <SEO title="Sitemap: About Car LockSmith Services" />
    <DetailsHead title="Sitemap"/>
    <SM/>
  </Layout>
)

export default Sitemap
